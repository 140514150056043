import React, { useRef } from "react";
import styled from "styled-components";
import Layout from "../components/layout";
import ArrowsWhitePath from "../images/arrows-white.svg";
import ArrowsBlackPath from "../images/arrows-black.svg";
import BackgroundImagePath from "../images/rail/nxrt-rail_bg.jpg";
import DarkBackgroundImagePath from "../images/nxrt-background-blue.svg";
import LightBackgroundImagePath from "../images/nxrt-background.svg";
import BackgroundImage1Path from "../images/rail/nxrt-rail4.png";
import BackgroundImage2Path from "../images/rail/nxrt-rail3.jpg";
import { Routes } from "../components/layout/header";
import RailsuiteImage1Path from "../images/rail/nxrt-rail.jpg";
import RailsuiteImage2Path from "../images/rail/nxrt-rail4.jpg";
import RailsuiteImage3Path from "../images/rail/nxrt-rail2.jpg";

//#region Styled Components

const StyledBackgroundContainer = styled.div`
  background: url('${DarkBackgroundImagePath}');
  background-size: cover;
`;

//#region Hero
const StyledHeroSection = styled.section`
  min-height: 900px;
  padding: 2rem 5rem 2rem 5rem;
  background: url('${BackgroundImagePath}');
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

const StyledHeroHeaderContainer = styled.div`
  max-width: 1344px;
  display: flex;
  justify-content: center;
  z-index: 1;
  pointer-events: none;
`;

const StyledHeroHeader = styled.h1`
  text-transform: uppercase;
  line-height: 1.2;

  > span {
    display: inline-block;
  }

  > span:nth-of-type(1) {
    margin-top: 15rem;
    margin-left: 4rem;
    font-weight: 300;
    font-size: 8rem;
    text-transform: uppercase;
    letter-spacing: 14px;
  }

  > span:nth-of-type(2) {
    margin-left: 25rem;
    font-weight: 300;
    font-size: 6rem;
    letter-spacing: 9px;
  }

  @media (max-width: 78rem) {
    > span:nth-of-type(1) {
      margin-top: 15rem;
      margin-left: 4rem;
      font-size: 4rem;
    }

    > span:nth-of-type(2) {
      margin-left: 30rem;
      font-size: 3rem;
    }
  }

  @media (max-width: 53rem) {
    > span:nth-of-type(1) {
      margin-top: 10rem;
    }

    > span:nth-of-type(2) {
      margin-left: 0;
      display: inline-block;
      width: 100%;
      text-align: right;
    }
  }

  @media (max-width: 44rem) {
    > span:nth-of-type(1) {
      margin-left: 2rem;
      font-size: 3rem;
    }

    > span:nth-of-type(2) {
      font-size: 2rem;
    }
  }
`;

const StyledHeroDescriptionContainer = styled.div`
  max-width: 1354px;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 1;
  pointer-events: none;
`;

const StyledHeroDescription = styled.div`
  max-width: 18rem;
  padding: 20px;
  border: 1px solid white;
  font-size: 1.3rem;
  line-height: 1.5;
`;

const StyledImageBackgrounds = styled.div`
  max-width: 1354px;
  width: calc(100% - 10rem);
  position: absolute;
  display: flex;
  flex-direction: column;

  > img {
    margin: 1.25%;
    align-self: flex-start;
    transition-duration: 0.3s;
    transition-property: transform;
    transition-timing-function: ease-out;
    opacity: 0.8;

    :hover {
      transform: translateY(-8px);
    }
  }

  > img:nth-child(1) {
    align-self: center;
    margin-top: 90px;
    width: 60%;
  }

  > img:nth-child(2) {
    align-self: flex-end;
    width: 35%;
  }
`;

const StyledHeroArrowsContainer = styled.div`
  margin-top: -5rem;
  max-width: 1354px;
  width: 100%;
  z-index: 1;
  height: 1px;
  margin-bottom: 10rem;
`;

const StyledHeroArrows = styled.img`
  margin-left: 5rem;
  height: 10rem;

  @media (max-width: 53rem) {
    margin-top: 5rem;
  }
`;
//#endregion

//#region Railsuit Section

const StyledRailsuitSection = styled.section`
  padding: 3rem;
  color: black;

  > h2 {
    margin-bottom: 3rem;
    font-size: 5.5rem;
    font-weight: 300;
    letter-spacing: 9px;
    text-transform: uppercase;
    text-align: center;
  }

  @media (max-width: 99rem) {
    padding: 3rem;
  }
`;

const StyledRailsuitText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > p {
    max-width: 40rem;
    font-size: 1.5rem;
    line-height: 1.5;
    text-align: center;

    :nth-child(2) {
      font-weight: bold;
    }
  }
`;

const StyledRailsuitImages = styled.div`
  margin: 10rem auto 0 auto;
  width: 60%;
  max-width: 68rem;
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 16rem auto auto;
  opacity: 0.6;

  @media (max-width: 75rem) {
    width: 100%;
  }

  @media (max-width: 46rem) {
    grid-template-rows: auto auto auto;
  }

  img {
    width: 100%;
    transition-duration: 0.3s;
    transition-property: transform;
    transition-timing-function: ease-out;

    :hover {
      transform: translateY(-8px);
    }
  }

  > img:nth-child(1) {
    grid-row: 1 / 2;
    grid-column: 1 / 2;
    width: 70%;
    margin-left: 30%;
  }

  > img:nth-child(2) {
    grid-row: 2 / 3;
    grid-column: 1 / 2;
  }

  > img:nth-child(3) {
    grid-row: 1 / 3;
    grid-column: 2 / 3;
    margin-top: 30%;
  }
`;

//#region Components Section
const StyledComponentsSection = styled.section`
  padding: 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;

  h2 {
    margin-top: 0;
    font-size: 3rem;
    text-transform: uppercase;
    text-align: center;
  }

  @media (max-width: 50rem) {
    padding: 5rem;
  }
`;

const StyledContentContainer = styled.div`
  margin-top: 15rem;
  width: 100%;
  max-width: 45rem;

  > p {
    font-size: 1.5rem;
    line-height: 1.5;
    text-align: center;
    font-weight: 300;
  }
`;

const StyledContentHeadline = styled.div`
  margin-bottom: 3rem;
  position: relative;

  > h3 {
    margin: 0;
    font-weight: normal;
    font-size: 3rem;
    text-align: center;
    text-transform: uppercase;
    position: relative;
  }

  > span {
    position: absolute;
    font-size: 15rem;
    line-height: 1;
    left: 50%;
    bottom: -5rem;
    transform: translateX(-50%);
    color: gray;
  }
`;

const StyledComponentsArrows = styled.img`
  margin-top: 6.5rem;
  height: 10rem;
`;
//#endregion

//#region Navigation
const StyledNavigationContent = styled.div`
  flex-grow: 1;
  background: url('${LightBackgroundImagePath}');
  background-size: cover;
  background-position-x: -900px;
`;
//#endregion

//#endregion

const IndexPage: React.FC = () => {
  const realtimeImmersionRef = useRef();
  const railsuitRef = useRef();

  return (
    <Layout currentRoute={Routes.IndustryRailAndConstruction}>
      <StyledBackgroundContainer>
        <StyledHeroSection>
          <StyledHeroHeaderContainer>
            <StyledHeroHeader>
              <span>Rail &amp;</span>
              <br />
              <span>Construction</span>
            </StyledHeroHeader>
          </StyledHeroHeaderContainer>
          <StyledHeroDescriptionContainer>
            <StyledHeroDescription>
              <p>
                <strong>RailSuite</strong> is the basis on which we create
                training simulations in the fields of railway operations and
                construction sites.
              </p>
            </StyledHeroDescription>
          </StyledHeroDescriptionContainer>
          <StyledHeroArrowsContainer>
            <StyledHeroArrows
              src={ArrowsWhitePath}
              onClick={() =>
                document
                  .getElementById("content")
                  .scrollIntoView({ behavior: "smooth" })
              }
            />
          </StyledHeroArrowsContainer>
          <StyledImageBackgrounds>
            <img src={BackgroundImage1Path} />
            <img src={BackgroundImage2Path} />
          </StyledImageBackgrounds>
        </StyledHeroSection>
      </StyledBackgroundContainer>
      <StyledNavigationContent id="content">
        <StyledRailsuitSection ref={railsuitRef}>
          <h2>Railsuite</h2>
          <StyledRailsuitText>
            <p>
              RailSuite is the basis on which we create highly realistic
              training simulations for various roles in railway operations and
              construction site. The RailSuite Platform will provide the right
              tools, to deliver training, that teaches about every single aspect
              of the rail industry, helps understanding regulations and improves
              interaction between man and machine while, at the same time,
              builds team dynamic.
            </p>
            <p>
              We at NXRT strongly believe that safety and education, not only
              for individuals but for groups, go hand in hand.
            </p>
            <p>
              Even the railroad is moving with the times! Professions change. We
              think about the world of tomorrow and bring people and technology
              together with our platform, not apart.
            </p>
          </StyledRailsuitText>
          <StyledRailsuitImages>
            <img src={RailsuiteImage1Path} />
            <img src={RailsuiteImage2Path} />
            <img src={RailsuiteImage3Path} />
          </StyledRailsuitImages>
        </StyledRailsuitSection>
        <StyledComponentsSection ref={realtimeImmersionRef}>
          <h2>Components</h2>
          <StyledContentContainer>
            <StyledContentHeadline>
              <span>1</span>
              <h3>Mishbild:</h3>
            </StyledContentHeadline>
            <p>
              MISHBILD replaces the windshield and windows of the drivers cab
              with virtual graphics by using a unique mixed reality HMD device.
            </p>
          </StyledContentContainer>
          <StyledComponentsArrows src={ArrowsBlackPath} />
          <StyledContentContainer>
            <StyledContentHeadline>
              <span>2</span>
              <h3>Simulation Experience:</h3>
            </StyledContentHeadline>
            <p>
              Our platform provides a comprehensive virtual world of railway
              operations and construction sites with all their components and
              processes. Scenarios can be adapted flexibly to the situation thus
              responding to the individual or the group.
            </p>
          </StyledContentContainer>
          <StyledComponentsArrows src={ArrowsBlackPath} />
          <StyledContentContainer>
            <StyledContentHeadline>
              <span>3</span>
              <h3>Realistic Controls:</h3>
            </StyledContentHeadline>
            <p>
              By combining the virtual graphics with realistic or original
              controls, the level of reality increases enormously. The amount of
              controls and the size of the drivers cab is fully scalable.
            </p>
          </StyledContentContainer>
        </StyledComponentsSection>
      </StyledNavigationContent>
    </Layout>
  );
};

export default IndexPage;
